import { usePostHog } from 'posthog-js/react'
import { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import Spinner from './spinner'
import { LightColorButton, SubtleButton } from '../styles/ui/button'
import { LoadingSpinner } from './loading'
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, TierCheckIcon } from '../styles/ui/icons'
import { Language, useLocalization } from '../hooks/useLocalization'
import { getUserSetting, UserSettings } from '../data/user/settings'
import { useRecoilValue } from 'recoil'
import { SessionValue } from '../globals/state'

export function Survey(props: { onSurveyClose: (failed: boolean) => void }): JSX.Element {
    const localization = useLocalization()
    const settings = useRecoilValue(SessionValue('settings')) as UserSettings

    const runOnce = useRef(false)
    const posthog = usePostHog()
    const [survey, setSurvey] = useState<any>(null)
    const [answers, setAnswers] = useState<any>({})
    useEffect(() => {
        try {
            if (!posthog) {
                return
            }
            if (runOnce.current) {
                return
            }
            runOnce.current = true
            posthog.getSurveys((surveys) => {
                surveys = surveys.filter((survey: any) => survey.end_date === null)
                // Get attrition  survey
                let attritionSurvey = surveys.find((survey: any) => survey.name === 'NAI Attrition Survey')
                if (getUserSetting(settings, 'uiLanguage') === Language.jp) {
                    attritionSurvey = surveys.find(
                        (survey: any) => survey.name === 'NAI Attrition Survey (JP)'
                    )
                }
                if (attritionSurvey) {
                    setSurvey(attritionSurvey)
                    posthog.capture('survey shown', {
                        $survey_id: attritionSurvey.id,
                    })
                } else {
                    props.onSurveyClose(true)
                }
            }, true)
        } catch {
            props.onSurveyClose(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posthog])

    const surveyElement = (question: any, i: number) => {
        switch (question.type) {
            case 'multiple_choice': {
                return (
                    <MultipleChoice
                        key={i}
                        id={i.toString()}
                        question={question.question}
                        choices={question.choices}
                        answer={answers[i.toString()] ?? []}
                        setAnswer={(answer: string[]) => {
                            setAnswers((prevAnswers: any) => ({
                                ...prevAnswers,
                                [i.toString()]: answer,
                            }))
                        }}
                    />
                )
            }
            case 'open': {
                return (
                    <OpenText
                        key={i}
                        id={i.toString()}
                        question={question.question}
                        answer={answers[i.toString()] ?? ''}
                        setAnswer={(answer: string) => {
                            setAnswers((prevAnswers: any) => ({
                                ...prevAnswers,
                                [i.toString()]: answer,
                            }))
                        }}
                        collapses={true}
                    />
                )
            }
            default: {
                return null
            }
        }
    }

    if (!survey) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <LoadingSpinner visible={true} />
            </div>
        )
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                {survey.questions.map((question: any, i: number) => {
                    return surveyElement(question, i)
                })}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                    }}
                >
                    <LightColorButton
                        onClick={() => {
                            props.onSurveyClose(false)
                            posthog.capture('survey dismissed', {
                                $survey_id: survey.id,
                            })
                        }}
                    >
                        {localization.common.skip}
                    </LightColorButton>
                    <LightColorButton
                        disabled={survey.questions.some(
                            (question: any, i: number) =>
                                !question.optional &&
                                (answers[i.toString()] === undefined || answers[i.toString()].length === 0)
                        )}
                        onClick={() => {
                            // If the question isn't optional, we need an answer
                            if (
                                survey.questions.some(
                                    (question: any, i: number) =>
                                        !question.optional &&
                                        (answers[i.toString()] === undefined ||
                                            answers[i.toString()].length === 0)
                                )
                            ) {
                                return
                            }
                            const answersToSend: any = {}
                            for (let i = 0; i < survey.questions.length; i++) {
                                if (i === 0) {
                                    answersToSend['$survey_response'] = answers[i.toString()]
                                } else {
                                    answersToSend[`$survey_response_${i}`] = answers[i.toString()]
                                }
                            }
                            posthog.capture('survey sent', {
                                $survey_id: survey.id,
                                ...answersToSend,
                            })
                            props.onSurveyClose(false)
                        }}
                    >
                        {localization.subscription.submitSurvey}
                    </LightColorButton>
                </div>
            </div>
        </div>
    )
}

function OpenText(props: {
    id: string
    question: string
    answer: string
    setAnswer: (answer: string) => void
    collapses?: boolean
}): JSX.Element {
    const [collapsed, setCollapsed] = useState(true)
    if (props.collapses) {
        return (
            <div>
                <SubtleButton
                    onClick={() => {
                        setCollapsed((v) => !v)
                    }}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <h3
                        style={{
                            margin: 0,
                        }}
                    >
                        {props.question}
                    </h3>
                    <div
                        style={{
                            marginBottom: '0.2rem',
                        }}
                    >
                        {collapsed ? <ArrowDownIcon highlight /> : <ArrowUpIcon highlight />}
                    </div>
                </SubtleButton>
                {!collapsed && (
                    <TextareaAutosize
                        value={props.answer}
                        onChange={(e) => props.setAnswer(e.target.value)}
                        style={{
                            width: '100%',
                        }}
                        minRows={6}
                        maxRows={16}
                    />
                )}
            </div>
        )
    }

    return (
        <div>
            <h3>{props.question}</h3>
            <TextareaAutosize
                value={props.answer}
                onChange={(e) => props.setAnswer(e.target.value)}
                style={{
                    width: '100%',
                    borderRadius: '3px',
                }}
                minRows={8}
                maxRows={16}
            />
        </div>
    )
}

function MultipleChoice(props: {
    id: string
    question: string
    choices: string[]
    answer: string[]
    setAnswer: (answer: string[]) => void
}): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                gap: '5px',
                flexDirection: 'column',
            }}
        >
            <h3>{props.question}</h3>
            {props.choices.map((choice) => (
                // <label
                //     key={choice}
                //     style={{
                //         display: 'flex',
                //         alignItems: 'center',
                //         justifyContent: 'space-between',
                //         padding: '0.5rem',
                //     }}
                // >
                //     <span
                //         style={{
                //             flex: '0 0 auto',
                //         }}
                //     >
                //         {choice}
                //     </span>
                //     <input
                //         style={{
                //             width: '1rem',
                //         }}
                //         type="checkbox"
                //         name={props.id}
                //         value={choice}
                //         checked={props?.answer?.includes(choice)}
                //         onChange={() => {
                //             if (props?.answer?.includes(choice)) {
                //                 props.setAnswer(props.answer.filter((a) => a !== choice))
                //             } else {
                //                 props.setAnswer([...props.answer, choice])
                //             }
                //         }}
                //     />
                // </label>
                <LightColorButton
                    key={choice}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0.5rem',
                        width: '100%',
                        textAlign: 'left',
                        borderRadius: '3px',
                    }}
                    onClick={() => {
                        if (props?.answer?.includes(choice)) {
                            props.setAnswer(props.answer.filter((a) => a !== choice))
                        } else {
                            props.setAnswer([...props.answer, choice])
                        }
                    }}
                >
                    <span>{choice}</span>
                    {props?.answer?.includes(choice) ? (
                        <TierCheckIcon highlight />
                    ) : (
                        <TierCheckIcon
                            style={{
                                backgroundColor: 'transparent',
                            }}
                        />
                    )}
                </LightColorButton>
            ))}
        </div>
    )
}
