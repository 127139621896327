import styled from 'styled-components'
import { MOBILE_WIDTH } from '../../data/image/constants'
import { CSSProperties } from 'react'

export const Warning = styled.span`
    color: ${(props) => props.theme.colors.warning} !important;
`
export const HideMobile = styled.div<{ breakpoint?: number }>`
    display: contents;
    @media (max-width: ${(props) => props.breakpoint || MOBILE_WIDTH}px) {
        display: none !important;
    }
`
export const HideNonMobile = styled.div<{ breakpoint?: number }>`
    display: contents;
    @media (min-width: ${(props) => (props.breakpoint || MOBILE_WIDTH) + 0.5}px) {
        display: none !important;
    }
`
export const ClampLines = styled.div<{ lines: number }>`
    overflow: hidden;
    text-overflow: ellipsis;
    // Should be replaced with line-clamp when/if it becomes availiable
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp for more information
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lines};
    -webkit-box-orient: vertical;
`

type ColumnDefinition = string[] | number
type RowDefinition = string[] | number

export const Grid = styled.div<{
    columns?: ColumnDefinition
    rows?: RowDefinition
    gap?: string | number
    breakpoints?: {
        width: number
        columns?: ColumnDefinition
        rows?: RowDefinition
        gap?: string | number
        style?: CSSProperties
    }[]
}>`
    display: grid;
    // If columns is a number, use that many columns evenly spaced. Otherwise it's an array of column widths. Auto if undefined
    grid-template-columns: ${(props) =>
        props.columns
            ? typeof props.columns === 'number'
                ? `repeat(${props.columns}, 1fr)`
                : props.columns.join(' ')
            : 'auto'};
    // If rows is a number, use that many rows evenly spaced. Otherwise it's an array of row heights. Auto if undefined
    grid-template-rows: ${(props) =>
        props.rows
            ? typeof props.rows === 'number'
                ? `repeat(${props.rows}, 1fr)`
                : props.rows.join(' ')
            : 'auto'};
    // gap can be either a number of pixels or a string with a unit.
    grid-gap: ${(props) =>
        props.gap ? (typeof props.gap === 'number' ? `${props.gap}px` : props.gap) : '0'};

    ${(props) =>
        (props.breakpoints ?? []).map(
            (bp) => `
        @media (max-width: ${bp.width}px) {
            ${
                bp.columns
                    ? `grid-template-columns: ${
                          typeof bp.columns === 'number' ? `repeat(${bp.columns}, 1fr)` : bp.columns.join(' ')
                      };`
                    : ''
            }
            ${
                bp.rows
                    ? `grid-template-rows: ${
                          typeof bp.rows === 'number' ? `repeat(${bp.rows}, 1fr)` : bp.rows.join(' ')
                      };`
                    : ''
            }
            ${bp.gap ? `grid-gap: ${typeof bp.gap === 'number' ? `${bp.gap}px` : bp.gap};` : ''}
            ${bp.style ? `${bp.style}` : ''}
        }
    `
        )}
`

export const Space = styled.div<{
    height?: number
    width?: number
    breakpoints?: { breakpointWidth: number; height?: number; width?: number }[]
}>`
    height: ${(props) => props.height ?? 0}px;
    width: ${(props) => props.width ?? 0}px;
    ${(props) =>
        (props.breakpoints ?? []).map(
            (bp) => `
        @media (max-width: ${bp.breakpointWidth}px) {
            ${bp.height ? `height: ${bp.height}px;` : ''}
            ${bp.width ? `width: ${bp.width}px;` : ''}
        }
    `
        )}
    flex: 0 0 auto;
`
